import { Link } from "gatsby";
import React from "react";
import { cn, buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import BlockText from "./block-text";

import * as styles from "./product-preview.module.css";
import { responsiveTitle3, link } from "./typography.module.css";
import { Figure } from "./figure";

function ProductPreview(props) {
  return (
    <>
      <Link className={styles.root} to={`/product/${props.slug.current}`}>
        <div className={styles.video}>
          {props.mainImage && props.mainImage.asset && (
            <div className={styles.mainImage}>
              <Figure mode="fill" node={props.mainImage} />
            </div>
          )}
        </div>
        <div className="text-mobile-sm flex justify-between mt-2">
          <h3>{props.title}</h3>
          {props.publishedAt && <div>{props.publishedAt}</div>}
        </div>
      </Link>
    </>
  );
}

export default ProductPreview;
