import React, { useState, useEffect } from "react";
import LogoStackedIcon from "./icon/logoStacked";
import LogoIcon from "./icon/logo";
import ProjectPreview from "./project-preview";
import ProductPreview from "./product-preview";
import { Figure } from "./figure";
import * as s from "./splash.module.css";
import { motion } from "framer-motion";
import { useCookies } from "react-cookie";
// Mobile
// 1. Show random images on mobile from homepage
// 2. Fade out

// Desktop
// 1. Load in section above project overview
// 2. Fade out white box to reveal selection of projects underneath
const Splash = ({ items }) => {
  const [featured, setFeatured] = useState(null);
  const [cookies, setCookie] = useCookies(["splash"]);

  useEffect(() => {
    // Get 2 random items if more than 2 features projects
    if (items.length >= 2) {
      let arr = [];
      while (arr.length < 2) {
        let r = Math.floor(Math.random() * items.length);
        if (arr.indexOf(r) === -1) arr.push(r);
      }
      setFeatured([items[arr[0]], items[arr[1]]]);
    } else {
      setFeatured(items);
    }
  }, []);

  useEffect(() => {
  }, [featured]);

  const animationComplete = () => {
    setCookie("splash", true);
  };

  return (
    <>
      {!cookies.splash && (
        <motion.section
          onAnimationComplete={animationComplete}
          animate={{ opacity: [1, 1, 1, 0] }}
          transition={{ duration: 1, delay: 2 }}
          className="bg-white md:hidden pointer-events-none fixed top-0 left-0 h-full w-full z-50 flex items-center justify-center"
        >
          <div className="block md:hidden grid grid-rows-3 w-full h-full">
            {featured?.map((featuredItem, i) => {
              if (featuredItem._type === "project") {
                return (
                  <motion.div
                    animate={{ opacity: [0, 1, 1, 0] }}
                    transition={{ duration: 2, delay: 0.25 }}
                    className={`m-3 opacity-0 ${i === 0 ? s.firstImage : s.lastImage}`}
                  >
                    <Figure loading="eager" mode="fill" node={featuredItem.mainImage} />
                  </motion.div>
                );
              } else {
                return (
                  <motion.div
                    animate={{ opacity: [0, 1, 1, 0] }}
                    transition={{ duration: 2, delay: 0.25 }}
                    className={`m-3 opacity-0 ${i === 0 ? s.firstImage : s.lastImage}`}
                  >
                    <Figure loading="eager" mode="fill" node={featuredItem.mainImage} />
                  </motion.div>
                );
              }
            })}

            <motion.div
              animate={{ opacity: [1, 1, 1, 0] }}
              transition={{ duration: 2.25 }}
              className="opacity-0 flex justify-center items-center relative"
            >
              <LogoStackedIcon />
            </motion.div>
          </div>

          {/* <div className="hidden md:grid grid-rows-3 w-full h-full">
          {featured?.map((featuredItem, i)=>{
          if(featuredItem._type === "project") {
            return <motion.div animate={{ opacity: [0, 1, 1, 0]}} transition={{ duration: 3, delay: 1 }} className={`m-3 opacity-0 ${i === 0 ? s.firstImage : s.lastImage}`}><Figure loading="eager" mode="contain" node={featuredItem.mainImage} /></motion.div>
          } else {
            return <motion.div animate={{ opacity: [0, 1, 1, 0]}} transition={{ duration: 3, delay: 1 }} className={`m-3 opacity-0 ${i === 0 ? s.firstImage : s.lastImage}`}><Figure loading="eager" mode="contain" node={featuredItem.mainImage} /></motion.div>
          }
        })}
 
        <motion.div animate={{ opacity: [0, 1, 1, 0]}} transition={{ duration: 4 }} className="opacity-0 flex justify-center items-center relative">
        <div className={s.logo}><LogoIcon /></div>
        </motion.div>
  
      </div> */}
        </motion.section>
      )}
      {/* <section>
      <div className={s.logo}><LogoIcon /></div>
    </section> */}
    </>
  );
};

export default Splash;
