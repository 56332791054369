import React from "react";
import { graphql } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
  cn,
} from "../lib/helpers";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import ProjectPreviewGrid from "../components/project-preview-grid";
import ProjectPreview from "../components/project-preview";
import ProductPreview from "../components/product-preview";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import BackgroundImage from "gatsby-background-image";
import { responsiveTitle3, base } from "../components/typography.module.css";
import { getGatsbyImageData } from "gatsby-source-sanity";
import clientConfig from "../../client-config";
import { Figure } from "../components/figure";
import { gridLayout } from "../components/project-preview-grid.module.css";
import Splash from "../components/splash";
import { motion } from "framer-motion";

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      mainImage {
        asset {
          _id
          url
        }
      }
    }
    home: sanityHome(_id: { regex: "/(drafts.|)home/" }) {
      overviewTitle
      selectionTitle
      featured {
        ... on SanityProject {
          id
          _type
          title
          slug {
            current
          }
          location
          mainImage {
            asset {
              _id
              gatsbyImageData(backgroundColor: "", placeholder: DOMINANT_COLOR)
            }
            alt
          }
        }
        ... on SanityProduct {
          id
          _type
          title
          publishedAt(formatString: "YYYY")
          slug {
            current
          }
          mainImage {
            asset {
              _id
              gatsbyImageData(backgroundColor: "", placeholder: DOMINANT_COLOR)
            }
            alt
          }
        }
      }
      overview {
        ... on SanityProject {
          id
          _type
          title
          slug {
            current
          }
          location
          mainImage {
            asset {
              _id
              gatsbyImageData(backgroundColor: "", placeholder: DOMINANT_COLOR)
            }
            alt
          }
        }
        ... on SanityProduct {
          id
          _type
          title
          publishedAt(formatString: "YYYY")
          slug {
            current
          }
          mainImage {
            asset {
              _id
              gatsbyImageData(backgroundColor: "", placeholder: DOMINANT_COLOR)
            }
            alt
          }
        }
      }
    }
    projects: allSanityProject(sort: { fields: orderRank, order: ASC }) {
      edges {
        node {
          mainImage {
            asset {
              _id
              gatsbyImageData(backgroundColor: "", placeholder: DOMINANT_COLOR)
            }
            alt
          }
          _rawExcerpt
          _rawDescription
          location
          title
          slug {
            current
          }
          id
        }
      }
    }
  }
`;

const IndexPage = (props) => {
  const { data, errors, location } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const home = (data || {}).home;
  const projectNodes = data.projects ? mapEdgesToNodes(data.projects) : [];
  const seoImage = site.mainImage ? `${site.mainImage.asset.url}?w=1200&h=600` : "";

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout location={location}>
      <SEO
        title={site.title}
        description={site.description}
        keywords={site.keywords}
        image={seoImage}
      />
      <Container>
        <Splash items={home.featured} />
        <div className="py-28.5 md:pb-32.5 md:pt-0">
          {/* <h1 className={cn(base, "md:text-center  mt-0 mb-5 md:mb-5", "md:-mt-28.5")}>
            {home.selectionTitle}
          </h1> */}

          <div className="grid grid-flow-row gap-7.5 md:mt-50vh md:pt-12">
            {home.featured?.map((featuredItem, i) => {
              if (featuredItem._type === "project") {
                return <ProjectPreview key={`preview-${i}`} {...featuredItem} />;
              } else {
                return <ProductPreview key={`preview-${i}`} {...featuredItem} />;
              }
            })}
          </div>
          <h2 className={cn(base, "md:text-center mt-28.5 mb-5 md:my-5 2xl:mt-14.5")}>
            {home.overviewTitle}
          </h2>
          <div className={gridLayout}>
            {home.overview?.map((overviewItem) => {
              if (overviewItem._type === "project") {
                return <ProjectPreview {...overviewItem} />;
              } else {
                return <ProductPreview {...overviewItem} />;
              }
            })}
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default IndexPage;
