import React from 'react'

const LogoStackedIcon = () => (
    <svg className="absolute left-0 top-0 object-contain w-full h-full p-6" width="352" height="146" viewBox="0 0 352 146" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M93.46 27.7475C100.85 26.3075 105.27 21.6275 105.27 15.1775C105.27 14.6375 105.24 14.1075 105.18 13.5675C104.74 9.54748 102.53 5.94748 99.22 3.64748C94.97 0.68748 89.81 0.73748 84.82 0.73748H75.47H61.42V1.16748L65.92 5.67748V55.1975L61.42 59.7875V60.2175H87.34C101.19 60.2175 109.35 54.0975 109.35 43.6475C109.35 34.8075 103.31 28.7675 93.46 27.7475ZM76.21 3.27748H78.33C89.12 3.27748 95.58 8.28748 95.58 16.3575C95.58 22.8975 91.33 27.0675 84.28 27.0675H76.21V3.27748V3.27748ZM83.43 56.9775H76.21V30.2175H81.74C93.04 30.2175 98.73 34.6375 98.73 43.4775C98.72 52.0575 93.2 56.9775 83.43 56.9775Z" fill="black"/>
<path d="M191.02 47.6375L177.77 56.9775H163.83V5.65754L168.25 1.15754V0.727539H149.05V1.15754L153.55 5.65754V55.1975L149.05 59.7875V60.2075H191.45V47.6375H191.02Z" fill="black"/>
<path d="M229.77 56.9575H215.83V5.62751L220.25 1.12751V0.69751H201.05V1.12751L205.55 5.62751V55.1675L201.05 59.7575V60.1775H243.45V47.6075H243.03L229.77 56.9575Z" fill="black"/>
<path d="M117.74 0.727539V1.15754L122.24 5.74754V55.1975L117.74 59.7875V60.2075H136.83V59.7875L132.32 55.1975V5.74754L136.83 1.15754V0.727539H117.74Z" fill="black"/>
<path d="M275.63 1.15754L281.07 6.84754L267.99 27.7475L254.22 5.74754L258.38 1.15754V0.727539H240.09H239.48V1.15754L244.05 5.74754L260.07 31.2375V55.1975L255.57 59.7875V60.2075H274.77V59.7875L270.27 55.1975V31.2375L286.08 6.33754L290.25 1.15754V0.727539H275.63V1.15754Z" fill="black"/>
<path d="M82.71 83.4976C66.82 83.4976 53.74 95.4776 53.74 114.858C53.74 133.128 65.21 145.538 81.44 145.538C97.41 145.538 110.42 133.558 110.42 114.178C110.41 95.8176 99.02 83.4976 82.71 83.4976ZM83.05 141.968C77.36 141.968 72.85 139.418 69.54 134.318C66.31 129.128 64.7 122.078 64.7 112.988C64.7 96.0776 70.65 87.0676 81.1 87.0676C86.79 87.0676 91.3 89.6176 94.53 94.7976C97.84 99.8976 99.46 106.948 99.46 116.038C99.45 132.958 93.5 141.968 83.05 141.968Z" fill="black"/>
<path d="M114.77 95.9076H115.19L127.51 88.0876H136.5V139.248L132.08 143.838V144.258H151.28V143.838L146.78 139.248V88.0876H156.35L168.08 95.9076H168.5V84.7776H114.77V95.9076Z" fill="black"/>
<path d="M263.7 83.4976C247.81 83.4976 234.73 95.4776 234.73 114.858C234.73 133.128 246.2 145.538 262.43 145.538C278.41 145.538 291.41 133.558 291.41 114.178C291.41 95.8176 280.02 83.4976 263.7 83.4976ZM264.04 141.968C258.35 141.968 253.84 139.418 250.53 134.318C247.3 129.128 245.69 122.078 245.69 112.988C245.69 96.0776 251.64 87.0676 262.09 87.0676C267.78 87.0676 272.29 89.6176 275.51 94.7976C278.82 99.8976 280.44 106.948 280.44 116.038C280.44 132.958 274.5 141.968 264.04 141.968Z" fill="black"/>
<path d="M177.39 95.9076H177.82L190.14 88.0876H199.12V139.248L194.7 143.838V144.258H213.91V143.838L209.4 139.248V88.0876H218.98L230.71 95.9076H231.13V84.7776H177.39V95.9076Z" fill="black"/>
<path d="M345.25 84.7776H343.63H335.7V85.1976L342.31 91.8876V126.028L342.24 125.738L312.67 84.7776H311.63H305.8H304.19H303.05H298.36V85.1976L302.86 89.7876V137.148L298.36 143.838V144.258H304.19H305.8H313.73V143.838L307.13 137.148L307.23 94.6376L343.17 144.258H346.57V91.8876L351.08 85.2076V84.7776H345.25Z" fill="black"/>
<path d="M32.63 138.738C20.05 138.738 11.05 127.518 11.05 111.888C11.05 97.5276 18.87 86.9876 29.23 86.9876C30.51 86.9876 31.69 86.9876 32.29 87.2476L45.55 97.6176H45.97V86.4776C42.23 84.5176 37.3 83.4976 31.95 83.4976C13.34 83.4976 0.599976 96.7576 0.599976 115.708C0.599976 132.878 11.99 145.538 27.62 145.538C38.5 145.538 47.08 139.248 48.61 129.898L48.27 129.728C45.21 135.418 39.51 138.738 32.63 138.738Z" fill="black"/>
</svg>
)

export default LogoStackedIcon